import axios from "../utils/axios";
import {IExecutor} from "./ExecutorRequest";
import {ServerError} from "../error/ServerError";

export enum WorkReportStatus {
    PENDING  = 'pending',
    SENT     = 'sent'
}

export interface IWorkReport {
    id: string;
    executor: IExecutor;
    createdAt: string;
    period: string;
    status: WorkReportStatus;
}

export interface IWorkReportValues {
    customers: {[key: string]: string},
    values: {[key: string]: {
            [key: string]: {id: string, day: number, spent_time: number|null}}
        },
    period: number,
    updatedAt?: string|null,
    status?: WorkReportStatus,
    id: string,
}

export const fetchWorkReportList = async (page: number): Promise<{
    pages: number;
    data: IWorkReport[];
    meta: {canCreateCurrent: boolean, canCreatePrev: boolean}
}> => {
    const data = await axios.get(`/frontend-api/report/work`, {
        params: { page}
    });

    return data.data;
};

export const createWorkReport = async (period: string = 'current'): Promise<void> => {
    const data = await axios.post(`/frontend-api/report/work?period=${period}`);

    return data.data;
};

export const fetchReport = async (reportId: string): Promise<IWorkReportValues> => {
    const data = await axios.get(`/frontend-api/report/work/${reportId}`);

    return data.data;
};

export const updateWorkReportCustomerLesson = async (reportId: string, lessonId: string, value: number): Promise<IWorkReportValues> => {
    const data = await axios.patch(`/frontend-api/report/work/${reportId}/lesson/${lessonId}`, { value });

    return data.data;
};

export const send = async (reportId: string|undefined): Promise<IWorkReportValues> => {
    const data = await axios.patch(`/frontend-api/report/work/${reportId}/send`);

    return data.data;
};

export const addReportWorkCustomer = async (reportId: string, customerId: string): Promise<IWorkReportValues> => {
    try {
        const data = await axios.post(`/frontend-api/report/work/${reportId}/customer/${customerId}`);

        return data.data;
    } catch (e: any) {
        throw new ServerError(e.message);
    }
};