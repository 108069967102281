import {FC, useEffect, useState} from "react";
import "./styles.css";
import {Box, Button, Card, useTheme} from "@mui/material";
import InputMask from 'react-input-mask';
import {fetchAvailableOfficesForExecutor, IOffice} from "../../../request/OfficeRequest";
import EditLessonPlanConfirmation from "../components/EditLessonPlanConfirmation";
import {Edit} from "@mui/icons-material";
import { v4 as uuid } from 'uuid'
import {
    addReportPlanCustomer,
    deleteReportPlanCustomer,
    fetchPlanReport,
    IPlanReport,
    IPlanReportItem,
    IPlanReportItemLesson,
    saveReportCustomer
} from "../../../request/ReportPlanRequest";
import {format} from "date-fns";
import {useParams} from "react-router-dom";
import {ICustomer} from "../../../request/CustomerRequest";
import DeleteCustomerConfirmation from "../components/DeleteCustomerConfirmation";
import AddCustomerModal from "../components/AddCustomerModal";
import toast, {Toaster} from "react-hot-toast";

const defaultPlanReportItemLesson: IPlanReportItemLesson = {
    day: null,
    time: {
        startAt: null,
        endAt: null,
    },
    office: {
        id: null
    }
}

const DAYS = [
    {id: 'monday', title: 'Понедельник'},
    {id: 'tuesday', title: 'Вторник'},
    {id: 'wednesday', title: 'Среда'},
    {id: 'thursday', title: 'Четверг'},
    {id: 'friday', title: 'Пятница'},
    {id: 'saturday', title: 'Суббота'},
    {id: 'sunday', title: 'Воскресение'},
];

const sorterDayOfWeek = {
    "monday": 1,
    "tuesday": 2,
    "wednesday": 3,
    "thursday": 4,
    "friday": 5,
    "saturday": 6,
    "sunday": 7
}

const PlanReport: FC = () => {
    const {id} = useParams();
    const [offices, setOffices] = useState<IOffice[]|null>(null);
    const theme = useTheme();

    const [nextCustomer, setNextCustomer] = useState<ICustomer|null>(null);
    const [currentCustomer, setCurrentCustomer] = useState<ICustomer|null>(null);
    const [currentPlanReportItem, setCurrentPlanReportItem] = useState<IPlanReportItem|null>(null);

    const [planReport, setPlanReport] = useState<IPlanReport|null>(null);
    const [editLessonPlanConfirmation, setEditLessonPlanConfirmation] = useState(false);
    const [deleteCustomerConf, setDeleteCustomerConfirmation] = useState(false);
    const [showAddNewCustomer, setShowAddNewCustomer] = useState(false);

    useEffect(() => {
        (async () => {
            const { data } = await fetchAvailableOfficesForExecutor();
            setOffices([
                {id: 'at_teacher_home', title: 'У преподавателя дома', enabled: true},
                {id: 'at_student_home', title: 'У клиента на дому', enabled: true},
                {id: 'online', title: 'Online', enabled: true},
                ...data,
            ]);
        })();
    }, []);

    useEffect(() => {
        if (!id) return

        (async () => {
            const data = await fetchPlanReport(`${id}`);
            setPlanReport(data)
        })();
    }, [id]);

    const onEditContract = (contract: ICustomer) => {
        setNextCustomer(contract)
        setEditLessonPlanConfirmation(true)
    }

    const onSaveLessonPlan = async () => {
        const res = await saveReportCustomer(
            planReport && planReport.id ? planReport?.id : uuid(),
            new Date(),
            currentPlanReportItem
        );

        // @ts-ignore
        setPlanReport(res)

        setCurrentCustomer(null)
        setCurrentPlanReportItem(null)
        toast.success("План для клиента отредактирован!");
    }

    const themeMode = theme.palette.mode === "light" ? 'light' : 'dark'

    return <>
        {planReport !== null ? <>
            <Box pt={2} pb={4}>
                <Card sx={{padding: 2, marginBottom: '20px'}} className='input-area'>

                    <div
                        className='input-area__fullname'
                        style={{lineHeight: '25px'}}
                    >
                        <div>
                            <strong>Период:</strong>
                            <span style={{ marginLeft: '10px', width: '150px'}}>
                          {planReport?.period ? format(new Date(+planReport?.period * 1000), "MM / yyyy") : '-'}
                      </span>
                        </div>

                        <div>
                            <strong>Дата последнего обновления:</strong>
                            <span style={{ marginLeft: '10px', width: '150px'}}>
                          {planReport?.updatedAt ? format(new Date(+planReport?.updatedAt * 1000), "dd.MM.yyyy HH:mm") : '-'}
                      </span>
                        </div>

                        {null !== currentCustomer ? <div >
                            <strong>Клиент:</strong>
                            <span style={{ marginLeft: '10px', width: '150px'}}>
                          {currentCustomer.fullName.length ? <>
                              {currentCustomer.fullName}
                          </> : <>+{currentCustomer.phone}</>}

                      </span>
                        </div> : null}

                        {null === currentCustomer ? <div style={{position: 'absolute', right: 0, top: '7px'}}>
                            <Button
                                variant="contained"
                                color="primary"
                                size='small'
                                sx={{ height: '25px' }}
                                style={{ position: 'absolute', right: '10px', width: '221px'}}
                                onClick={() => setShowAddNewCustomer(true)}
                            >
                                Добавить клиента в отчет
                            </Button>
                        </div> : null}

                        {null !== currentCustomer ? <div style={{position: 'absolute', right: 0, top: '7px'}}>
                            <Button
                                onClick={() => setDeleteCustomerConfirmation(true)}
                                variant="contained"
                                color="warning"
                                size='large'
                                sx={{ height: '25px' }}
                                className='lesson-plan delete-action'
                            >
                                Удалить клиента из отчета
                            </Button>
                            <Button
                                onClick={() => setCurrentCustomer(null)}
                                variant="contained"
                                color="error"
                                size='small'
                                sx={{ height: '25px' }}
                                className='lesson-plan cancel-action'
                            >
                                Отмена
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                size='small'
                                sx={{ height: '25px' }}
                                className='lesson-plan save-action'
                                onClick={onSaveLessonPlan}
                            >
                                Сохранить
                            </Button>
                        </div> : null}
                    </div>

                    {null !== currentCustomer ? <>
                        <div style={{ marginTop: '25px', display: 'flex', marginBottom: '25px' }}>

                            <div>
                                <label>
                                    <span><strong>План посещений, часы</strong></span>
                                    <input
                                        name='visitPlan'
                                        style={{marginLeft: '10px', width: '150px'}}
                                        type='number'
                                        value={`${currentPlanReportItem?.visitAmount}`}
                                        onChange={
                                            (val) => {
                                                setCurrentPlanReportItem({
                                                    ...currentPlanReportItem,
                                                    customer: currentCustomer,
                                                    visitAmount: val.target.value
                                                })
                                            }}
                                    />
                                </label>
                            </div>

                            {/*

                      <div>
                          <div>
                              <label>
                                  <span><strong>Оплата</strong></span>
                                  <input
                                      name='paymentAmount'
                                      style={{marginLeft: '10px', width: '150px'}}
                                      type='number'
                                      value={`${currentPlanReportItem?.paymentAmount}`}
                                      onChange={
                                          (val) => {

                                              let item: IPlanReportItem = null !== currentPlanReportItem
                                                  ? currentPlanReportItem
                                                  : defaultPlanReportItem;

                                              if (!item.lessons?.length) {
                                                  item = {
                                                      ...item,
                                                      lessons: DAYS.map(day => ({...defaultPlanReportItemLesson, day: day.id}))
                                                  }
                                              }

                                              setCurrentPlanReportItem({
                                                  ...item,
                                                  customer: currentContract?.customer,
                                                  paymentAmount: val.target.value
                                              })
                                          }}
                                  />
                              </label>
                          </div>
                      </div>

                      */}


                        </div>




                        <div className='days-input-wrapper' >

                            {DAYS.map((day, index) =>
                                <div className='day-input-wrapper' key={index}>
                                    <div style={{width: '100px'}}>
                                        <strong>{day.title}</strong>
                                    </div>

                                    <div style={{ display: 'flex', gap: '3%'}}>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <label>
                                                Офис
                                            </label>
                                            <select
                                                defaultValue={`${currentPlanReportItem?.lessons?.find(item => (item?.day) === (day.id))?.office?.id}`}
                                                onChange={
                                                    (val) => {
                                                        // @ts-ignore
                                                        let lesson: IPlanReportItemLesson = currentPlanReportItem.lessons?.find(lesson => lesson.day === day.id)
                                                            ? currentPlanReportItem?.lessons?.find(lesson => lesson.day === day.id)
                                                            : defaultPlanReportItemLesson

                                                        setCurrentPlanReportItem({
                                                            ...currentPlanReportItem,
                                                            customer: currentCustomer,
                                                            lessons: [].concat(
                                                                // @ts-ignore
                                                                currentPlanReportItem.lessons && currentPlanReportItem.lessons.length ? currentPlanReportItem.lessons?.filter(lesson => lesson.day !== day.id) : [],
                                                                [{...lesson, day: day.id, office: {id: val.target.value}}]
                                                            )
                                                        })
                                                    }}
                                            >
                                                <option></option>
                                                {offices?.filter(office => office.enabled).map(office =>
                                                    <option
                                                        key={office.id}
                                                        value={office.id}
                                                    >
                                                        {office.title}
                                                    </option>
                                                )}
                                            </select>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <label >
                                                Время нач.
                                            </label>
                                            <InputMask
                                                mask="99:99"
                                                value={`${currentPlanReportItem?.lessons?.find(item => (item.day) === (day.id))?.time?.startAt}`}
                                                onChange={
                                                    (val) => {
                                                        // @ts-ignore
                                                        let lesson: IPlanReportItemLesson = currentPlanReportItem.lessons?.find(lesson => lesson.day === day.id)
                                                            ? currentPlanReportItem?.lessons?.find(lesson => lesson.day === day.id)
                                                            : defaultPlanReportItemLesson

                                                        setCurrentPlanReportItem({
                                                            ...currentPlanReportItem,
                                                            customer: currentCustomer,
                                                            lessons: [].concat(
                                                                // @ts-ignore
                                                                currentPlanReportItem.lessons && currentPlanReportItem.lessons.length ? currentPlanReportItem.lessons?.filter(lesson => lesson.day !== day.id) : [],
                                                                [{...lesson, day: day.id, time: {...lesson.time, startAt: val.target.value}}]
                                                            )
                                                        })
                                                    }}
                                            />
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <label>
                                                Время оконч.
                                            </label>

                                            <InputMask
                                                mask="99:99"
                                                value={`${currentPlanReportItem?.lessons?.find(item => (item.day) === (day.id))?.time?.endAt}`}
                                                onChange={
                                                    (val) => {
                                                        // @ts-ignore
                                                        let lesson: IPlanReportItemLesson = currentPlanReportItem.lessons?.find(lesson => lesson.day === day.id)
                                                            ? currentPlanReportItem?.lessons?.find(lesson => lesson.day === day.id)
                                                            : defaultPlanReportItemLesson

                                                        setCurrentPlanReportItem({
                                                            ...currentPlanReportItem,
                                                            customer: currentCustomer,
                                                            lessons: [].concat(
                                                                // @ts-ignore
                                                                currentPlanReportItem.lessons && currentPlanReportItem.lessons.length ? currentPlanReportItem.lessons?.filter(lesson => lesson.day !== day.id) : [],
                                                                [{...lesson, day: day.id, time: {...lesson.time, endAt: val.target.value}}]
                                                            )
                                                        })
                                                    }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            )}

                        </div>
                    </> : null}


                </Card>


                <Card sx={{paddingTop: 3, paddingBottom: 3, paddingRight: 1, paddingLeft: 1}}>
                    <div style={{ overflow: "scroll"}}>
                        <table className='pivot_table'>
                            <thead>
                            <tr>
                                <th className='main_th_cell'>
                                    #
                                </th>
                                <th className='main_th_cell'>
                                    ФИО
                                </th>
                                {/*
                                <th className='main_th_cell'>
                                    Телефон
                                </th>
                                */}

                                {DAYS.map(day =>
                                    <th className='main_th_cell' key={day.id}>
                                        {day.title}
                                    </th>)
                                }
                                <th className='main_th_cell'>
                                    План посещений, часы
                                </th>

                                {/*
                          <th className='main_th_cell'>
                              Оплата
                          </th>
                          */}

                            </tr>
                            <tr>
                                <th className='main_th_cell'>
                                </th>
                                <th className='main_th_cell'>
                                </th>
                                {/*
                                <th className='main_th_cell'>
                                </th>
                                */}

                                {DAYS.map((day) => <th className='main_th_cell' key={day.id}>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th className='nested_th_cell'>
                                                Офис | Время
                                            </th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </th>)}
                                {/*
                                <th className='main_th_cell'>
                                </th>


                          <th className='main_th_cell'></th>
                          */}
                            </tr>
                            </thead>

                            <tbody>
                            <>
                                {planReport?.items?.map((item, index) =>
                                    <tr
                                        onClick={() => onEditContract(item.customer)}
                                        style={{cursor: 'pointer'}}
                                        className={currentCustomer && currentCustomer.id === item.customer.id ? `selected-contract ${themeMode}` : undefined}
                                        key={item.customer?.id}
                                    >
                                        <td className='main_td_cell'>
                                            {index + 1}
                                            <Edit />
                                        </td>

                                        <td className='main_td_cell'>
                                            {item?.customer?.fullName.length ? <>
                                                {item?.customer?.fullName}
                                            </> : '-'}
                                        </td>

                                        {/*
                                        <td className='main_td_cell'>{item?.customer?.phone ? item?.customer.phone : '-'}</td>
                                        */}

                                        {item.lessons?.sort(function (a, b) {
                                            // @ts-ignore
                                            let day1 = a?.day?.toLowerCase();
                                            let day2 = b?.day?.toLowerCase();
                                            // @ts-ignore
                                            return sorterDayOfWeek[day1] - sorterDayOfWeek[day2];
                                        }).map((value, index) => <td key={index} className='main_td_cell'>
                                            <table style={{ width: '100%'}}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {offices?.find(office => office.id === value.office?.id)?.title ?? '-'}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            {value.time?.startAt ? value.time?.startAt : '-'} / {value.time?.endAt ? value.time?.endAt : '-'}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>


                                            {/*

                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td className='nested_td_cell'>
                                                        {value.time?.startAt ? value.time?.startAt : '-'} / {value.time?.endAt ? value.time?.endAt : '-'}
                                                    </td>
                                                    <td className='nested_td_cell'>
                                                        {offices?.find(office => office.id === value.office?.id)?.title ?? '-'}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            */}

                                        </td>)}

                                        <td className='main_td_cell'>
                                            {planReport && planReport?.items?.find((planReportItem) => planReportItem?.customer?.id === item.customer.id)?.visitAmount
                                                ? planReport?.items?.find((planReportItem) => planReportItem?.customer?.id === item.customer.id)?.visitAmount
                                                : '-'
                                            }
                                        </td>

                                    </tr>
                                )}

                            </>
                            </tbody>
                        </table>
                    </div>
                </Card>
            </Box>



            <EditLessonPlanConfirmation
                open={editLessonPlanConfirmation}
                onClose={() => setEditLessonPlanConfirmation(false)}
                customer={nextCustomer}
                onSuccess={() => {
                    setCurrentCustomer(nextCustomer)
                    const planReportItem = planReport && planReport?.items?.find(
                        (planReportItem) => planReportItem?.customer?.id === nextCustomer?.id
                    )
                    // @ts-ignore
                    setCurrentPlanReportItem(planReportItem)
                    setNextCustomer(null)
                    setEditLessonPlanConfirmation(false)
                }}
            />

            <DeleteCustomerConfirmation
                open={deleteCustomerConf}
                onClose={() => setDeleteCustomerConfirmation(false)}
                customer={currentCustomer}
                onSuccess={(async () => {
                    // @ts-ignore
                    await deleteReportPlanCustomer(planReport?.id, currentCustomer?.id);

                    // @ts-ignore
                    setPlanReport({
                        ...planReport,
                        items: planReport?.items?.filter((item) => item.customer.id !== currentCustomer?.id)}
                    )

                    setCurrentCustomer(null)
                    setCurrentPlanReportItem(null)
                    setEditLessonPlanConfirmation(false)
                    setDeleteCustomerConfirmation(false)
                    toast.success("Клиент удален из отчета!");
                })}
            />

            {showAddNewCustomer ? <>
                <AddCustomerModal
                    open={showAddNewCustomer}
                    onClose={() => setShowAddNewCustomer(false)}
                    report={planReport}
                    excludeCustomerIds={planReport?.items?.map(item => item.customer.id) ?? []}
                    onSuccess={(async (customerId: string) => {
                        // @ts-ignore
                        const res = await addReportPlanCustomer(planReport?.id, customerId);

                        // @ts-ignore
                        setPlanReport({
                            ...planReport,
                            // @ts-ignore
                            items: [...planReport?.items, res.data]
                        })

                        setShowAddNewCustomer(false)
                        toast.success("Клиент добавлен к отчету!");
                    })}
                />
            </> : null}

        </> : null}
        <Toaster position="bottom-right" reverseOrder={false} />
  </>
};

export default PlanReport;
