import axios from "../utils/axios";

export interface IPivotPlanReport {
  id: string;
  day: string;
  start_at: string;
  end_at: string;
  customer: {
    id: string;
    fullName: string;
  }
  executor: {
    id: string;
    fullName: string;
  }
}

export const fetchPivotPlanReport = async (officeId: string, month: number, year: number): Promise<IPivotPlanReport[]> => {
  const data = await axios.get(`/frontend-api/report/pivot-plan-lesson`, {
    params: {
      officeId,
      month,
      year
    },
  });

  return data.data.data;
};