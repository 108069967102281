import {FC, useEffect, useState} from "react";
import "./styles.css";
import {Box, Button, Card, useTheme} from "@mui/material";
import {useParams} from "react-router-dom";
import {Edition, RevoGrid} from "@revolist/revogrid/dist/types/interfaces";
import {defineCustomElements} from "@revolist/revogrid/loader";
import {RevoGridCustomEvent} from "@revolist/revogrid/dist/types/components";
import NumberColumnType from "@revolist/revogrid-column-numeral";
import {RevoGrid as RevoGridComponent} from "@revolist/revogrid-react";
import toast, {Toaster} from "react-hot-toast";
import {
    addReportWorkCustomer,
    fetchReport,
    IWorkReportValues,
    send,
    updateWorkReportCustomerLesson
} from "../../../request/ReportWorkRequest";
import {format} from "date-fns";
import AddCustomerModal from "../components/AddCustomerModal";
import SendReportConfirmation from "../components/SendReportConfirmation";

const valueCellProperties = () => {
    return {
        style: {
            textAlign: 'center',
            maxWidth: '100px',
            boxShadow: '-1px 0 0 #f1f1f1 inset'
        },
    };
}

const valueColumnTemplate = (createElement: any, column: any) => {
    return createElement('div', {
        style: {
            whiteSpace: 'pre-wrap',
            lineHeight: '20px',
            fontWeight: 600,
            padding: '10px 0',
            writingMode: 'vertical-rl',
            transform: 'rotate(-180deg)',
        },
    }, column.name);
}

const periodCellProperties = () => {
    return {
        style: {
            color: 'black',
            fontWeight: 600,

        },
    };
}

const getDaysInMonth = (month: number, year: number) => {
    return new Date(year, month, 0).getDate();
}

const WorkReport: FC = () => {
    const {id} = useParams();
    const theme = useTheme();

    const themeMode = theme.palette.mode === "light" ? 'light' : 'dark'

    const [columns, setColumns] = useState<RevoGrid.ColumnRegular>()
    const [source, setSource] = useState<RevoGrid.DataType[]>()

    const [report, setReport] = useState<IWorkReportValues|null>(null);
    const [reportLoading, setReportLoading] = useState<boolean>(false);

    const [showAddNewCustomer, setShowAddNewCustomer] = useState(false);
    const [sendReportConf, setSendReportConf] = useState(false);

    useEffect(() => {
        defineCustomElements();
    }, [])

    //Получение данных
    useEffect(() => {
        (async () => {
            if (!id) {
                return;
            }

            setReportLoading(true)
            const data = await fetchReport(`${id}`);
            setReport(data);
            setReportLoading(false)
        })();
    }, [id]);

    // Установка колонок
    useEffect(() => {
        if (reportLoading || null === report)
            return

        updateColumns(report)
    }, [reportLoading])

    const updateColumns = (report: IWorkReportValues) => {
        const columns = [];

        columns.push({
            prop: "date",
            cellProperties: periodCellProperties,
            pin: 'colPinStart',

            readonly: true
        })

        for (const [id, name] of Object.entries(report.customers)) {
            columns.push({
                prop: id,
                name: name,

                columnType: 'numeric',
                columnTemplate: valueColumnTemplate,
                cellProperties: valueCellProperties,
            })
        }

        // @ts-ignore
        setColumns(columns)
    }


    const updateCells = (report: IWorkReportValues) => {
        const period = new Date(report.period * 1000)
        const periodMonth = period.getMonth() + 1
        const periodYear = period.getFullYear()
        const daysInMonth = getDaysInMonth(periodMonth, periodYear)

        const sources = [];

        for (const index of Array(daysInMonth).fill(1).map((x, y) => x + y)) {
            const val = {
                date: `${index}`.padStart(2, '0') + '.' + `${periodMonth}`.padStart(2, '0') + '.' + periodYear.toString().substr(-2)
            }

            for (const id of Object.keys(report.values)) {
                // @ts-ignore
                val[id] = report.values[id][index]['spent_time']
            }

            sources.push(val)
        }

        setSource(sources)
    }

    useEffect(() => {
        if (reportLoading || null === report)
            return

        updateCells(report)
    }, [reportLoading])

    const onEdit = async ({detail}: RevoGridCustomEvent<Edition.BeforeSaveDataDetails | Edition.BeforeRangeSaveDataDetails>) => {
        /* @ts-ignore */
        const ceilVal = detail.val;

        /* @ts-ignore */
        const rowIndex = detail.rowIndex;

        /* @ts-ignore */
        const customerId = detail.prop;

        const item = report?.values[customerId][rowIndex+1]
        const itemId = item?.id;

        try {
            // @ts-ignore
            const data = await updateWorkReportCustomerLesson(id, itemId, ceilVal)

            setReportLoading(true)
            setReport(data);
            setReportLoading(false)
            toast.success("Время зафиксировано");
        } catch (e) {

        }
    }

    const beforeEdit = (e: any) => {
        const val = e.detail.val;

        if (!val) {
            return
        }

        if (!Number.isInteger(+e.detail.val)) {
            toast.error("Невалидное значение");
            e.preventDefault()
        }
    }

    const plugin = { 'numeric': new NumberColumnType('0,0') };

    return <>
        {report ? <>
            <Box pt={2} pb={1}>
                <Card sx={{padding: 2}} className='input-area'>
                    <div
                        className='input-area__fullname'
                        style={{lineHeight: '25px'}}
                    >
                        <div>
                            <strong>Период:</strong>
                            <span style={{ marginLeft: '10px', width: '150px'}}>
                                {report?.period ? format(new Date(+report?.period * 1000), "MM / yyyy") : '-'}
                            </span>
                        </div>

                        <div>
                            <strong>Дата последнего обновления:</strong>
                            <span style={{ marginLeft: '10px', width: '150px'}}>
                                {report?.updatedAt ? format(new Date(+report?.updatedAt * 1000), "dd.MM.yyyy HH:mm") : '-'}
                            </span>
                        </div>

                        <div>
                            <strong>Статус:</strong>
                            <span style={{ marginLeft: '10px', width: '150px'}}>
                                {report.status === 'pending'
                                    ? <span style={
                                        {
                                            backgroundColor: 'orange',
                                            color: 'white',
                                            padding: '4px',
                                            borderRadius: '5px'
                                        }}>
                                        Редактирование
                                </span>
                                    : <span style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        padding: '4px',
                                        borderRadius: '5px' }
                                    }>
                                        Отправлен
                                    </span>
                                }
                            </span>
                        </div>



                        {report.status === 'pending' ? <div style={{position: 'absolute', right: 0, top: '7px'}}>
                            <Button
                                variant="contained"
                                color="primary"
                                size='small'
                                sx={{ height: '25px' }}
                                style={{ position: 'absolute', right: '180px', width: '180px'}}
                                onClick={() => setShowAddNewCustomer(true)}
                            >
                                Добавить клиента
                            </Button>

                            <Button
                                variant="contained"
                                color="success"
                                size='small'
                                sx={{ position: 'absolute', right: '10px', height: '25px' }}
                                onClick={() => setSendReportConf(true)}
                                style={{ width: '160px'}}
                            >
                                Отправить отчет
                            </Button>
                        </div> : null}

                    </div>
                </Card>
            </Box>

            {Object.values(report.customers).length ? <Box pt={2} pb={4} height="85vh">
                <Card sx={{padding: 4, height: '85vh'}}>
                    <RevoGridComponent
                        theme="compact"
                        /* @ts-ignore */
                        columns={columns}
                        canFocus={report.status === 'pending'}
                        //columns={[{ prop: "name" }]}
                        //source={ [{ name: "1" }, { name: "2" }]}
                        source={source}
                        columnTypes={plugin}
                        onBeforeedit={beforeEdit}
                        onAfteredit={(event: RevoGridCustomEvent<Edition.BeforeSaveDataDetails | Edition.BeforeRangeSaveDataDetails>) => onEdit(event)}
                    />
                </Card>
            </Box> : <Box pt={2} pb={4}><Card sx={{padding: 4}}>Нет клиентов</Card></Box>}

            {showAddNewCustomer ? <>
                <AddCustomerModal
                    open={showAddNewCustomer}
                    onClose={() => setShowAddNewCustomer(false)}
                    excludeCustomerIds={Object.keys(report.customers) ?? []}
                    onSuccess={(async (customerId: string) => {
                        // @ts-ignore
                        const res = await addReportWorkCustomer(report?.id, customerId);

                        setReport(res)
                        updateCells(res)
                        updateColumns(res)

                        setShowAddNewCustomer(false)
                        toast.success("Клиент добавлен");
                    })}
                />
            </> : null}
            <SendReportConfirmation
                open={sendReportConf}
                onClose={() => setSendReportConf(false)}
                onSuccess={(async () => {
                    // @ts-ignore
                    const data = await send(id);

                    setReport(data)
                    updateCells(data)
                    updateColumns(data)

                    setSendReportConf(false)
                    toast.success("Отчет отправлен!");
                })}
            />
        </> : null}

        <Toaster position="bottom-right" reverseOrder={false} />
    </>
};

export default WorkReport;
