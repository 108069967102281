import {FC, useEffect, useMemo, useState} from "react";
import "./styles.css";
import {fetchAvailableOfficesForExecutor, IOffice} from "../../request/OfficeRequest";
import {Box, Button, Card, Grid} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";
import AppTextField from "../../components/input-fields/AppTextField";
import {fetchPivotPlanReport, IPivotPlanReport} from "../../request/PivotPlanReportRequest";
import ScrollBar from "simplebar-react";

const MONTHS = [
    {val: 1, label: "Январь"},
    {val: 2, label: "Февраль"},
    {val: 3, label: "Март"},
    {val: 4, label: "Апрель"},
    {val: 5, label: "Май"},
    {val: 6, label: "Июнь"},
    {val: 7, label: "Июль"},
    {val: 8, label: "Август"},
    {val: 9, label: "Сентябрь"},
    {val: 10, label: "Октябрь"},
    {val: 11, label: "Ноябрь"},
    {val: 12, label: "Декабрь"},
];

const DAYS = [
    {val: 'monday', label: 'Понедельник'},
    {val: 'tuesday', label: 'Вторник'},
    {val: 'wednesday', label: 'Среда'},
    {val: 'thursday', label: 'Четверг'},
    {val: 'friday', label: 'Пятница'},
    {val: 'saturday', label: 'Суббота'},
    {val: 'sunday', label: 'Воскресение'},
];

const YEARS = [
    {val: 2024, label: 2024},
    {val: 2025, label: 2025},
    {val: 2026, label: 2026},
    {val: 2027, label: 2027},
    {val: 2028, label: 2028},
];

const DEFAULT_OFFICES = {loading: true, data: []};

const PivotPlanReport: FC = () => {
    const [offices, setOffices] = useState<{loading: boolean; data: IOffice[]}>(DEFAULT_OFFICES);
    const [report, setReport] = useState<IPivotPlanReport[]|null>(null);

    useEffect(() => {
        (async () => {
            setOffices({loading: true, data: []});
            const { data } = await fetchAvailableOfficesForExecutor();
            setOffices({loading: false, data: data});
        })();
    }, []);

    const defaultMonth = useMemo(() => new Date().getMonth() + 1, []);
    const defaultYear = useMemo(() => new Date().getFullYear(), []);

    const [office, setOffice] = useState<string | null>('');
    const [month, setMonth] = useState<number | null>(defaultMonth);
    const [year, setYear] = useState<number | null>(defaultYear);

    return <>
        {!offices.loading ? <Box pt={2} pb={4}>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <AppTextField
                        size="medium"
                        select

                        fullWidth
                        label="Офис"
                        variant="outlined"
                        value={office}
                        onChange={(e) => setOffice(e.target.value) }
                        SelectProps={{ native: true, IconComponent: KeyboardArrowDown }}
                    >
                        <option value=''></option>
                        {
                            offices.data.map((office, index) => (
                                <option value={office.id} key={index}>{office.title}</option>
                            ))
                        }
                    </AppTextField>
                </Grid>
                <Grid item md={3} xs={12}>
                    <AppTextField
                        size="medium"
                        select
                        fullWidth
                        label="Месяц"
                        variant="outlined"
                        value={month}
                        onChange={(e) => setMonth(+e.target.value) }
                        SelectProps={{ native: true, IconComponent: KeyboardArrowDown }}
                    >
                        {
                            MONTHS.map((val, index) => (
                                <option value={val.val} key={index}>{val.label}</option>
                            ))
                        }
                    </AppTextField>
                </Grid>
                <Grid item md={3} xs={12}>
                    <AppTextField
                        size="medium"
                        select
                        fullWidth
                        label="Год"
                        variant="outlined"
                        value={year}
                        onChange={(e) => setYear(+e.target.value) }
                        SelectProps={{ native: true, IconComponent: KeyboardArrowDown }}
                    >
                        {
                            YEARS.map((val, index) => (
                                <option value={val.val} key={index}>{val.label}</option>
                            ))
                        }
                    </AppTextField>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={async () => {
                            if (!(office && month && year)) {
                                setReport(null)
                                return;
                            }

                            const data = await fetchPivotPlanReport(office, month, year)
                            setReport(data)
                        }}
                        style={{height: '100%'}}
                    >
                        Применить
                    </Button>
                </Grid>
            </Grid>
        </Box> : null}

        {
            null === report ? <Box>
                Не найдено записей. <br />Проверьте правильность установленных фильтров.
            </Box> : <>
                {DAYS.map((day, index) => <Card style={{ marginBottom: '15px', padding: '15px'}} key={index}>
                    <ScrollBar>
                        <div style={{ fontSize: '20px', marginBottom: '15px'}}>{ day.label }</div>

                        {report?.filter(item => item.day === day.val).length ? <table
                            className='pivot-plan-report'
                            style={{ maxWidth: '100%', minWidth: '100%'}}
                        >
                            <thead>
                            <tr>
                                <th style={{ maxWidth: '25%', minWidth: '25%'}}>Время</th>
                                <th>Исполнитель</th>
                                <th>Ученик</th>
                            </tr>
                            </thead>
                            <tbody>
                            {report?.filter(item => item.day === day.val).map((item, index) => <tr key={index}>
                                <td style={{ maxWidth: '25%', minWidth: '25%'}}>{ item?.start_at } - { item?.end_at }</td>
                                <td>{ item?.executor?.fullName }</td>
                                <td>{ item?.customer?.fullName }</td>
                            </tr>)}

                            </tbody>
                        </table> : <span style={{ fontSize: '14px' }}>Нет записей</span>}
                    </ScrollBar>
                </Card>)}
            </>
        }
    </>
}

export default PivotPlanReport;
