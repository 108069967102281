import {Box, Button, CircularProgress, Stack, styled} from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import CustomTable from "page-sections/admin-ecommerce/CustomTable";
import {FC, useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import useAuth from "hooks/useAuth";
import {subscribe, unsubscribe} from "components/Event";
import {useNavigate} from "react-router-dom";
import PlanReportColumnShape from "./components/PlanReportColumnShape";
import {createPlanReport, fetchPlanReportList, IPlanReport} from "../../../request/ReportPlanRequest";
import {Info} from "@mui/icons-material";
import toast, {Toaster} from "react-hot-toast";

export const HeadingWrapper = styled(FlexBox)(({ theme }) => ({
  marginBottom: 20,
  flexWrap: "wrap",
  [theme.breakpoints.down(530)]: {
    "& .MuiButton-root": { width: "100%" },
    "& .MuiInputBase-root": { maxWidth: "100%", marginBottom: 15 },
  },
}));

const PlanReportList: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const [pageValue, setPageValue] = useState(1);
  const handlePage = (value: number) => setPageValue(value);

  const [filteredItem, setFilteredItem] = useState<IPlanReport[]>([]);
  const [countItems, setCountItems] = useState(1);
  const [loading, setLoading] = useState(false);
  const [canCreate, setCanCreate] = useState<boolean>(false);

  const {user} = useAuth();

  const load = async () => {
    setLoading(true)
    const { data, pages, meta } = await fetchPlanReportList(pageValue);
    setFilteredItem(data);
    setCanCreate(meta.canCreate)
    setCountItems(pages);
    setLoading(false)
  }

  const navigateToDetails = (rowData: object) => {
    // @ts-ignore
    return () => navigate(`/dashboard/plan-report/${rowData.id}`);
  };

  useEffect(() => {
    subscribe('FRESH_CLASS_SCHEDULE_REPORT_LIST', load);

    return () => {
      unsubscribe('FRESH_CLASS_SCHEDULE_REPORT_LIST', load);
    }
  }, [searchValue]);

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }

      load();
    })();
  }, []);

  return (
    <>
      {!loading ? <Box pt={2} pb={4}>
        <Box display='flex' gap='15px'>
          {canCreate ? <Button
              variant="contained"
              color="warning"
              onClick={async () => {
                await createPlanReport()
                await load()
                toast.success("Отчет по плану занятий создан!");
              }}
          >
            Создать план
          </Button> : null}

        </Box>

        <Box display='flex' gap='5px' style={{fontSize: '12px', alignItems: 'center', marginTop: '20px'}}>
          <Info />
          <i>Создать отчет можно за текущий и следующий месяцы</i>
        </Box>

        {filteredItem.length ? <>
          <CustomTable
              columnShape={PlanReportColumnShape}
              data={filteredItem}
              rowClick={(data) => navigateToDetails(data)}
          />
        </> : <Box pt={2} pb={4}>Нет созданных планов</Box>}
      </Box> : <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, height: "100vh" }}
      >
        <CircularProgress />
      </Stack>
      }
      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};

export default PlanReportList;
