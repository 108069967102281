import { Small } from "components/Typography";
import {format} from "date-fns";
import {IconButton} from "@mui/material";
import {Edit} from "@mui/icons-material";

const WorkReportColumnShape = [
    {
        Header: "Период",
        accessor: "period",
        Cell: ({ value }: any) => (
            <Small color="text.secondary">{
                format(new Date(value * 1000), "MM / yyyy")
            }</Small>
        ),
    },

    {
        Header: "Статус",
        accessor: "status",
        Cell: ({ value }: any) => (
            <Small color="text.secondary">
                {value === 'pending'
                    ? <span style={
                        {
                            backgroundColor: 'orange',
                            color: 'white',
                            padding: '4px',
                            borderRadius: '5px'
                        }}>
                                    Редактирование
                            </span>
                        : <span style={{
                            backgroundColor: 'green',
                            color: 'white',
                            padding: '4px',
                            borderRadius: '5px' }
                        }>
                                        Отправлен
                                    </span>
                }
            </Small>
        ),
    },

  {
    Header: "Действие",
    accessor: "action",
    Cell: ({ row }: any) => {
        return (
            <div>
                <>
                    {row.original.canEdit &&
                        <IconButton
                            title="Редактировать"
                        >
                            <Edit sx={{ fontSize: 18, color: "text.disabled" }} color="success" />
                        </IconButton>
                    }
                </>
            </div>
        );
    },
  },
];

export default WorkReportColumnShape;
