export class ServerError extends Error {
  private readonly error: string;

  constructor(error: string) {
    super('');

    this.error = error;

    this.name = 'ServerError';
  }

  public getError() {
    return this.error;
  }
}
