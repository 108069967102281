import { createContext, ReactNode, useEffect, useReducer } from "react";
import LoadingScreen from "components/LoadingScreen";
import axios from "../utils/axios";
import {
  fetchSignedIn,
  fetchSwitchToCabinet,
  IUser,
  setNewPasswordAnonRequest,
  signIn,
  signUp,
} from "../request/UserRequest";

// --------------------------------------------------------
type AuthUser = null | IUser;

type InitialAuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};

// props type
type AuthProviderProps = { children: ReactNode };
// --------------------------------------------------------

const initialState: InitialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const isValidToken = (accessToken: string) => {
   if (!accessToken) return false;

   //const decodedToken = jwtDecode<{ exp: number }>(accessToken);
   const currentTime = Date.now() / 1000;
   //return decodedToken.exp > currentTime;
};

 const setSession = (accessToken: string | null) => {
   if (accessToken) {
     localStorage.setItem('accessToken', accessToken);
     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
   } else {
     localStorage.removeItem('accessToken');
     delete axios.defaults.headers.common.Authorization;
   }
 };

const reducer = (state: InitialAuthState, action: any) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }
    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user,  };
    }
    case "LOGOUT": {
      return { ...state, user: null, isAuthenticated: false };
    }
    case "REGISTER": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => {},
  updateUser: (user: IUser) => {},
  login: (username: string, password: string) => Promise.resolve(),
  switchToCabinet: (id: string, date: string, signature: string) => Promise.resolve(),
  register: (publicKey: string, privateKey: string) => Promise.resolve(),
  createSignUpRequest: (email: string, password: string) => Promise.resolve(),
  setNewPasswordAnon: (privateCode: string, publicCode: string, password: string, password_confirmation: string) => Promise.resolve(),
});

export const JWTAuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username: string, password: string) => {
    const { accessToken, refreshToken } = await signIn(username, password);
    setSession(accessToken);
    const user = await fetchSignedIn();

    dispatch({ type: "LOGIN", payload: { user } });
  };

  const switchToCabinet = async (id: string, date: string, signature: string) => {
    try {
      const { accessToken, refreshToken } = await fetchSwitchToCabinet(id, date, signature);
      setSession(accessToken);
      const user = await fetchSignedIn();

      dispatch({ type: "LOGIN", payload: { user } });
    } catch (e) {

    }
  };

  const updateUser = async (user: IUser) => {
    dispatch({ type: "LOGIN", payload: { user } });
  };

  const register = async (publicCode: string, privateCode: string) => {
    const { accessToken, refreshToken } = await signUp(publicCode, privateCode);
    setSession(accessToken);
    const user = await fetchSignedIn();
    dispatch({ type: "REGISTER", payload: { user } });
  };

  const createSignUpRequest = async (username: string, password: string) => {
    await createSignUpRequest(username, password);
  };

  const setNewPasswordAnon = async (privateCode: string, publicCode: string, password: string, password_confirmation: string) => {
    const { accessToken, refreshToken } = await setNewPasswordAnonRequest(
        privateCode,
        publicCode,
        password,
        password_confirmation
    );
    setSession(accessToken);
    const user = await fetchSignedIn();
    dispatch({ type: "REGISTER", payload: { user } });
  }

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");

        if (accessToken) {
           setSession(accessToken);
           const user = await fetchSignedIn();
           dispatch({
             type: "INIT",
             payload: { user, isAuthenticated: true },
           });
         } else {
           dispatch({
             type: "INIT",
             payload: { user: null, isAuthenticated: false },
           });
         }
      } catch (err) {
        dispatch({
          type: "INIT",
          payload: { user: null, isAuthenticated: false },
        });
      }
    })();
  }, []);

  // show loading until not initialized
  if (!state.isInitialized) <LoadingScreen />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, switchToCabinet, register, updateUser, logout, createSignUpRequest, setNewPasswordAnon }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
