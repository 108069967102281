import axios from "../utils/axios";
import {ICustomer} from "./CustomerRequest";
import {ServerError} from "../error/ServerError";

export interface IPlanReport {
  id?: string|null;
  updatedAt?: string|null;
  period?: number|null;
  canEdit: boolean;
  items?: IPlanReportItem[]|null;
}

export interface IPlanReportItem {
  customer: ICustomer,
  visitAmount?: string|null;
  paymentAmount?: string|null;
  lessons?: IPlanReportItemLesson[]|null;
}

export interface IPlanReportItemLesson {
  day: string|null;
  time: {
    startAt: string|null;
    endAt: string|null;
  },
  office: {
    id: string|null;
  }|null;
}

export const saveReportCustomer = async (
    reportId: string|null,
    period: Date,
    values: IPlanReportItem|null
): Promise<{}> => {
  const data = await axios.patch(`/frontend-api/report/plan/${reportId}/lesson`, {
    customerId: values?.customer?.id,
    period: Math.floor(period.getTime() / 1000),

    paymentAmount: values?.paymentAmount,
    visitAmount: values?.visitAmount,
    lessons: values?.lessons?.map(lesson => ({
      day: lesson.day,
      startAt: lesson?.time?.startAt,
      endAt: lesson?.time?.endAt,
      officeId: lesson.office?.id,
    }))
  });

  return data.data.data;
};

export const fetchPlanReportList = async (page: number): Promise<{ pages: number; data: IPlanReport[], meta: {canCreate: boolean } }> => {
  const data = await axios.get(`/frontend-api/report/plan`, {
    params: { page}
  });

  return data.data;
};

export const deleteReportPlanCustomer = async (reportId: string, customerId: string): Promise<void> => {
  const data = await axios.delete(`/frontend-api/report/plan/${reportId}/customer/${customerId}`);

  return data.data;
};

export const addReportPlanCustomer = async (reportId: string, customerId: string): Promise<void> => {
  try {
    const data = await axios.post(`/frontend-api/report/plan/${reportId}/customer/${customerId}`);

    return data.data;
  } catch (e: any) {
    throw new ServerError(e.message);
  }
};

export const fetchPlanReport = async (id: string): Promise<IPlanReport> => {
  const data = await axios.get(`/frontend-api/report/plan/${id}`);

  return data.data.data;
};

export const createPlanReport = async (): Promise<IPlanReport> => {
  const data = await axios.post(`/frontend-api/report/plan`);

  return data.data;
};