import {
  Button,
  Grid,
  styled,
} from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import {H3} from "components/Typography";
import {FC, SyntheticEvent, useEffect, useState} from "react";
import {fetchExecutorContracts, IContract} from "../../../request/ContractRequest";
import {KeyboardArrowDown} from "@mui/icons-material";
import AppTextField from "../../../components/input-fields/AppTextField";

// component props interface
interface Props {
  open: boolean;
  onClose: () => void;
    excludeCustomerIds: string[];
  onSuccess: (customerId: string) => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 700,
  minWidth: 300,
  outline: "none",
  padding: "1.5rem",
}));

const AddCustomerModal: FC<Props> = ({
 open,
 excludeCustomerIds,
 onClose,
 onSuccess
}) => {
  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

      try {
          // @ts-ignore
          await onSuccess(customerId);
      } catch (e) {
          // @ts-ignore
          setErrorMessage(e.getError())
      }
  }

    const [customerId, setCustomerId] = useState<string | null>(null);
    const [executorContracts, setExecutorContracts] = useState<IContract[]>([]);
    const [executorContractsLoading, setExecutorContractsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);

    useEffect(() => {
        (async () => {
            setExecutorContractsLoading(true)
            const { data } = await fetchExecutorContracts();
            const items = data.filter(item => !excludeCustomerIds.includes(item.customer.id)).sort(function (a, b) {
                if (a.customer.lastName < b.customer.lastName) {
                    return -1;
                }
                if (a.customer.lastName > b.customer.lastName) {
                    return 1;
                }
                return 0;
            })
            setExecutorContracts(items);

            if (items.length) {
                setCustomerId(items[0].customer.id)
            }

            setExecutorContractsLoading(false)
        })();
    }, [excludeCustomerIds.join('')]);

  return (
    <StyledAppModal open={open} handleClose={onClose}>

        {errorMessage !== null ? <>
            <H3 marginBottom={2} style={{textAlign: 'center'}}>
                {errorMessage}
            </H3>
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <FlexBox justifyContent="center" gap={2} marginTop={2}>
                            <Button variant="outlined" onClick={onClose}>
                                OK
                            </Button>
                        </FlexBox>
                    </Grid>
                </Grid>
            </form>
        </> : null}

        {errorMessage === null && !executorContractsLoading && !executorContracts.length ? <>
            <H3 marginBottom={2} style={{textAlign: 'center'}}>
                Нет клиентов для выбора.
            </H3>

            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <FlexBox justifyContent="center" gap={2} marginTop={2}>
                            <Button variant="outlined" onClick={onClose}>
                                OK
                            </Button>
                        </FlexBox>
                    </Grid>
                </Grid>
            </form>
        </> : null}

        {
            errorMessage === null && !executorContractsLoading && executorContracts.length ? <>
                <H3 marginBottom={2}>
                    Выберите из списка клиента, которого необходимо добавить в отчет:
                </H3>

                <AppTextField
                    size="medium"
                    select
                    fullWidth
                    label="Клиент"
                    variant="outlined"
                    onChange={(e) => setCustomerId(e.target.value) }
                    SelectProps={{ native: true, IconComponent: KeyboardArrowDown }}
                >
                    {
                        executorContracts?.map((val, index) => (
                            <option value={val.customer.id} key={index}>{val.customer.fullName}</option>
                        ))
                    }
                </AppTextField>

                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FlexBox justifyContent="flex-end" gap={2} marginTop={2}>
                                <Button fullWidth variant="outlined" onClick={onClose}>
                                    Отмена
                                </Button>
                                <Button fullWidth type="submit" variant="contained">
                                    Добавить
                                </Button>
                            </FlexBox>
                        </Grid>
                    </Grid>
                </form>
            </> : null}
    </StyledAppModal>
  );
};

export default AddCustomerModal;
